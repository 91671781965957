import React from "react";
import MyAvatar from '../../images/avatar.jpg'
import '../../styles/styles.css'

const Avatar = () => {
    return (
        <div className="avatar-container">
            <div className="avatar-border">
                <img className="avatar" src={MyAvatar} alt="Avatar" />
            </div>
        </div>
    )
}

export default Avatar