import './App.css';
import './styles/styles.css'
import { Routes, Route } from "react-router-dom";

// Pages
import HomePage from './pages/Homepage';
import MinVinPrivacyPage from './pages/MinVinPrivacyPage'

function App() {
  return (
    <div className="App">
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route path="min-vin-privatlivspolitik" element={<MinVinPrivacyPage />} />
      </Routes>
    </div>
  );
}

export default App;
