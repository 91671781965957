import React from "react";
import '../styles/minvinstyles.css'

const MinVinPrivacyPage = () => {
    return (
        <div className="minvin">
            <h1>MinVin</h1>
            <h2>Privatlivspolitik</h2>
            <p>Når du benytter MinVin indsamles der INGEN oplysninger om dig. Al data gemmes lokalt på din enhed, og forsvinder ved afinstallation af appen. Billeder vil dog forblive i din enheds mediebibliotek.</p>
            <p className="ejes-af">Appen ejes og drives af:</p>
            <p>Mikkel Krogsgaard Hansen</p>
            <p>Skejbygårdsvej 40, 8240 Risskov</p>
            <p>info@mikkel-hansen.dk</p>
        </div>
    )
}

export default MinVinPrivacyPage