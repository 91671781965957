import React from "react";
import '../styles/styles.css'

// Components
import Avatar from "../components/home/Avatar";
import PersonalInfo from "../components/home/PersonalInfo";

const HomePage = () => {
    return (
        <div className="section">
            <Avatar />
            <PersonalInfo />
        </div>
    )
}

export default HomePage