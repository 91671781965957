import React from "react";
import '../../styles/styles.css'

import {MdPhoneEnabled, MdEmail} from "react-icons/md";

const PersonalInfo = () => {
    return (
        <div>
            <h1>Mikkel Krogsgaard Hansen</h1>
            <h2>Full Stack Developer</h2>
            <div className="list-item">
                <MdPhoneEnabled className="list-item-icon" />
                <p>+45 21 13 16 02</p>
            </div>
            <div className="list-item">
                <MdEmail className="list-item-icon" />
                <p>info@mikkel-hansen.dk</p>
            </div>
        </div>
    )
}

export default PersonalInfo